"use strict";

import {translate} from '@elements/translations';

let selectedAdults = [];
let selectedChilds = [];
export function initInScope($scope) {

    //console.log('roomform');

    $(document.body).on('ROOMINFOCHANGE', function (evt, roomParam) {
        $('.js-room-form__room-param').each(function () {
            $(this).val(roomParam);
            setInputs($(this).closest('.js-room-form'), roomParam);
        });
    });

    function getRoomParamInput ($roomForm) {
        let roomParam;
        let roomType = $roomForm.find('.js-room-form__room-type').val();
        //let roomType = '0';
        let rooms = [];
        $roomForm.find('.js-room-form__row').each(function (index) {
            let ages = [];
            if ($(this).find('.js-room-form__row-adults')) {
                for (let i = 0; i < $(this).find('.js-room-form__row-adults').val(); i++) {
                    ages.push('18');
                }

                selectedAdults[index] = $(this).find('.js-room-form__row-adults').val();
            }

            $(this).find('.js-room-form__age, .js-room-form__age-childs').each(function () {
                ages.push($(this).val());
            });
            rooms.push(roomType + '-' + ages.join(','));
        });
        roomParam = rooms.join('|');
        return roomParam;
    }

    function setInputs ($roomForm, roomParam) {
         let roomTypeVal = roomParam.split('-')[0];
        let rooms = roomParam.replace(/\d-/g, '').split('|').map(function (roomString, index) {
            return roomString.split(',');
        });

         $roomForm.find('.js-room-form__room-type')
             .val(roomTypeVal)
             .trigger('programmaticChange');

        $roomForm.find('.js-room-form__select')
            .val(rooms.length)
            .trigger('programmaticChange');

        let $roomRows = $roomForm.find('.js-room-form__row');
        $roomRows.each(function (roomIndex, roomRow) {
            let $roomRow = $(roomRow);
            // $roomRow.find('.js-room-form__room-guest-number-select')
            //     .val(rooms[roomIndex].length)
            //     .trigger('programmaticChange');

            $roomRow.find('.js-room-form__row-adults').val(selectedAdults[roomIndex]).trigger('programmaticChange');
            $roomRow.find('.js-room-form__room-child-number-select').trigger('programmaticChange');

            // $roomRow.find('.js-room-form__age').each(function (personIndex, ageInput) {
            //     $(ageInput).val(rooms[roomIndex][personIndex]).trigger('programmaticChange');
            // });
        });
    }

    $scope.find('.js-room-form').each(function() {
        let $form = $(this);
        let $formRoomParamInput = $form.find('.js-room-form__room-param');
        let ageArray = "";

        if ($formRoomParamInput && $formRoomParamInput.length) {
            $form.on('change', function () {
                $formRoomParamInput.val(getRoomParamInput($form))

                let countAdults = 1;
                let countChildren = 0;
                ageArray = $formRoomParamInput.val().split(",");
                for (let i = 0; i < ageArray.length; i++) {
                    if (ageArray[i] === "18") {
                        countAdults++;
                    } else if (ageArray[i] !== "0-18") {
                        countChildren++;
                    }
                }
                console.log("count adults", countAdults, "count children:", countChildren);
                $formRoomParamInput.data("filter-label", countAdults + " " + translate("acco.adultAmount") + ", " + countChildren + " " + translate("acco.childAmount"));
            });
        }
    });

    $scope.find('.js-room-form__select').on('change programmaticChange', function (evt) {
        let rooms = $(this).val();
        let $roomForm = $(this).closest('.js-room-form');
        let $roomRows = $roomForm.find('.js-room-form__row');


        while ($roomRows.length > rooms) {
            $roomRows.last().remove();
            $roomRows = $roomForm.find('.js-room-form__row');
        }

        while ($roomRows.length < rooms) {
            let $newRoom = $roomRows.first().clone();
            $newRoom.find('.js-room-form__nr').html($roomRows.length + 1);
            $newRoom.data('room-row', $roomRows.length + 1);
            $newRoom.find('.js-room-form__room-child-number-select').val(0);
            $newRoom.find('.js-room-form__room-child-number-select').removeAttr('data-child-ages');
            $newRoom.find('.js-room-form__age-select-childs').empty();
            app.initModules($newRoom);

            // $newRoom.find('.js-room-form__room-guest-number-select').val(1).trigger('programmaticChange');

            $newRoom.find('[name]').each(function () {
                $(this).attr('name', $(this).attr('name').replace('1', $roomRows.length + 1))
            });
            $roomRows.last().after($newRoom);

            $roomRows = $roomForm.find('.js-room-form__row');
        }
    });

    // $scope.find('.js-room-form__room-guest-number-select').on('change programmaticChange', function (evt) {
    //     let personCount = $(this).val();
    //     let $roomRow = $(this).closest('.js-room-form__row');
    //     let $ageSelects = $roomRow.find('.js-room-form__age-select');
    //     let i = 0;
    //
    //     while ($ageSelects.length > personCount && i < 10) {
    //         $ageSelects.last().remove();
    //         $ageSelects = $roomRow.find('.js-room-form__age-select');
    //         i++;
    //     }
    //
    //     while ($ageSelects.length < personCount && i < 20) {
    //         let $newRoom = $ageSelects.first().clone();
    //         $newRoom.find('.js-room-form__person-nr').html($ageSelects.length + 1);
    //         $ageSelects.last().after($newRoom);
    //         $ageSelects = $roomRow.find('.js-room-form__age-select');
    //         i++;
    //     }
    // });

    $scope.find('.js-room-form__room-child-number-select').each(function (index) {
        if ($(this).data('child-ages')) {
            let $roomRow = $(this).closest('.js-room-form__row');
            let $childContainer = $roomRow.find('.js-room-form__age-select-childs');

            for (let i = 1; i <= $(this).data('child-ages').length; i++) {
                if (!selectedChilds[$roomRow.data('room-row')]) {
                    selectedChilds[$roomRow.data('room-row')] = [];
                }
                selectedChilds[$roomRow.data('room-row')][i]= $(this).data('child-ages')[i-1];

                $childContainer.append(renderChildAgeInput({
                    roomIndex: $roomRow.data('room-row'),
                    index: i, value: $(this).data('child-ages')[i-1], onChange: function (value) {
                        selectedChilds[$roomRow.data('room-row')][i]= value;
                    }
                }));
            }
        }
    });

    $scope.find('.js-room-form__room-child-number-select').on('change programmaticChange', function (evt) {
        let $roomRow = $(this).closest('.js-room-form__row');
        let $childContainer = $roomRow.find('.js-room-form__age-select-childs');

        $childContainer.empty();
        for (let i = 1; i <= $(this).val(); i++) {

            let value = 0;
            if (selectedChilds[$roomRow.data('room-row')]) {
                value = selectedChilds[$roomRow.data('room-row')][i] ? selectedChilds[$roomRow.data('room-row')][i] : 0
            }

            $childContainer.append(renderChildAgeInput({
                roomIndex: $roomRow.data('room-row'),
                index: i, value: value, onChange: function (value) {
                    if (!selectedChilds[$roomRow.data('room-row')]) {
                        selectedChilds[$roomRow.data('room-row')] = [];
                    }
                    selectedChilds[$roomRow.data('room-row')][i]= value;
                }
            }));
        }
    });

    $scope.find('.js-room-form__submit').on('click', function (evt) {
        let $form = $(this).closest('.js-room-form');
        let roomParam = getRoomParamInput($form);

        $form.find('.js-room-form__room-param').val(roomParam);
        $form.trigger('ROOMINFOCHANGE', roomParam);
    });

    $scope.find('.js-room-form__reset').on('click', function (evt) {
        let $form = $(this).closest('.js-room-form');
        setInputs($form, $form.find('.js-room-form__room-param').val());
    })
}

function renderChildAgeInput({roomIndex, index, value, onChange}) {
    let markup = `<div class="dropdown-item js-room-form__age-select"><label class="d-flex align-items-center justify-content-between mb-0">` +
        `<div>${translate('acco.childAge')} <span class="js-room-form__person-nr">${index}</span></div>` +
        `<div class="form-group"><div class="custom-select__select">` +
        `<select name="room-${roomIndex}-guest-age[]" class="form-control custom-select filter-select js-room-form__age-childs">` +
        `<option class="d-none" value="" ${ value ? '': 'selected'} ></option>`;

    for (let i = 0; i < 18; i++) {
        markup += `<option value="${i}" ${i == parseInt(value) ? 'selected': ''}>${i}</option>`;
    }

    markup += `</select>` +
        `</div></label></div>`;

    let $input = $(markup);

    $input.on('change', function (e) {
        onChange(e.target.value);
    });

    return $input;
}