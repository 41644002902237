"use strict";

import loadMapsAPI from './loadMapsAPI';
import {onEnterViewPort} from "@elements/viewport-utils";
import {initInScope as trustInit} from './trustYou';
import {initInScope as tripplanerInit} from './tripplaner/tripplaner-add';

export function initInScope($scope) {

    let alpsteinMap;
    let pendingDetailRequest;
    let markerIcon;

    let mapTypeIds = [
        'oa_map',
        'oa_map_winter'
    ];

    function Map (element) {
        let self = this;
        this.element = $(element);
        this.canvas = $(element).find('.js-map__canvas');
        this.map = null;
        this.marker = [];
        this.clustering = self.element.data('clustering');

        self.initMap();
    }

    Map.prototype.initMap = function () {
        let self = this;
        
        let mapOptions =  {
            center : {
                lat: this.element.data('lat') || 46.134096,
                lng: this.element.data('lng') || 11.288801
            },
            zoom : this.element.data('zoom') || 8,
            mapTypeId: mapTypeIds[_config.season == 'Winter' ? 1 : 0],
            mapTypeControlOptions: {
                mapTypeIds: mapTypeIds
            },
            mapInit : {
                basemap: "osm",
                style:   _config.season === 'Winter' ? 'winter' : 'summer',
                overlay: "slope"
            },
            oa: {
                overlayMapTypeIds: {
                    // Show overlay map type 'hiking' on top of all map types
                    '*': ['oa_hiking'],

                    // Show overlay map types 'avalanche' and 'skiresort' on winter map
                    'oa_map_winter': ['oa_avalanche_transp']
                }
            }
        };

        if(self.element.data('geomlayer')) {
            let content = self.element.data('tour-id').toString();

            let layerConfig = {
                markersActive : true,
                defaultIW : true
            };

            // initialize GeomLayer
            let layer;
            layer = new alpsteinMap.GeomLayer( content, layerConfig );

            layer.whenLoaded( initMap );

            function initMap() {
                let mapConfig = {
                    center: null,
                    bounds: layer.getBounds()
                };

                self.map = alpsteinMap.map( self.canvas.prop('id'), $.extend({}, mapOptions, mapConfig));

                // add GeomLayer to map
                layer.setMap( self.map );
            }
        } else {
            markerIcon = L.Icon.extend({
                options: {
                    iconSize:     [56, 56],
                    iconAnchor:   [28, 56],
                    popupAnchor:  [0, -56]
                }
            });
            
            this.markerOptions = new markerIcon({iconUrl: _config.season == 'Winter' ? '/static/build/img/maps/marker/marker-default-winter.png' : '/static/build/img/maps/marker/marker-default.png'});

            this.map = alpsteinMap.map(this.canvas.prop('id'), mapOptions);
            this.element.data('map', this);

            this.element.trigger('initialized');
            this.initialized = true;

            /*get poi variable*/
            let pois = this.element.data('poi');
            if (pois) {
                let marker = window;
                $.each(pois.split('.'), function (index, item) {
                    marker = marker[item];
                    if (!marker) {
                        console.error(marker + ' is not set, can not render map pois');
                    }
                });

                self.setMarker(marker);
            }

            if (self.element.data('poi-url')) {
                self.addMarkersFromUrl(self.element.data('poi-url'));
            }
        }
    };

    Map.prototype.setMarker = function (markers) {
        let self = this;
        if (this.markerCluster) {
            this.markerCluster.clearLayers();
            this.markerCluster = null;
        }

        this.marker = [];

        if (self.clustering) {
            import('leaflet.markercluster').then(function () {
                self.markerCluster = L.markerClusterGroup({polygonOptions: {color: '#de7000'}});
                self.addMarkers(markers);
                self.map.__alp_lf__.addLayer(self.markerCluster);
            });
        } else {
            self.addMarkers(markers);
        }
    };

    Map.prototype.addMarkersFromUrl = function (url) {
        let self = this;

        fetch(url, {
            method: 'GET',
            headers: {
                'X-Requested-With': 'XMLHttpRequest'
            },
            })
            .then(respones => respones.json())
            .then(function (response) {
                if (!Array.isArray(response)) {
                    response = [response];
                }

                self.setMarker(response);
            }).catch(function (a, b, c) {
            console.error('Can\'t load map marker from ' + url, a, b, c);
        });
    };

    Map.prototype.addMarkers = function (markers, preventCenter) {
        let self = this;

        if (!$.isArray(markers)) {
            markers = [markers];
        }

        for (let i= 0, poi; poi = markers[i]; i++) {
            if (!poi.lat || !poi.lon) {
                console.error('map error: could not draw marker. lat or lon is missing', poi);
                break;
            }

            let icon = self.markerOptions;
            if(poi.icon) {
                icon = new markerIcon({iconUrl: poi.icon});
            }

            let newMarker = L.marker([poi.lat, poi.lon], {icon: icon});

            if(poi.dataInfoAjaxUrl) {
                newMarker.dataInfoAjaxUrl = poi.dataInfoAjaxUrl;
            }

            if(poi.infowindow) {
                newMarker.infowindow = poi.infowindow;
            }

            //set dataId
            newMarker.dataId = poi.id;

            if (poi.infoboxId || poi.infoboxMarkup) {
                // loading marker infos via hidden elements
                let infoBoxMarkup = poi.infoboxMarkup;
                if (poi.infoboxId) {
                    infoBoxMarkup = $('#' + poi.infoboxId).clone().removeClass('hidden').html();
                }
                newMarker.infoBox = infoBoxMarkup;
            }

            self.marker.push(newMarker);
            if (!self.clustering) {
                newMarker.addTo(self.map.__alp_lf__);
            }

                newMarker.on('click', function () {
                    if (newMarker.isActive) {
                        self.deactivateAllMarkers();
                    } else {
                        self.deactivateAllMarkers();
                        self.activeMarker(newMarker);
                    }
                });
            // }


            if (self.clustering && self.markerCluster) {
                self.markerCluster.addLayer(newMarker);
                self.map.__alp_lf__.addLayer(self.markerCluster);
            }
        }

        if (!preventCenter) {
            self.centerMap();
        }
    };

    Map.prototype.removeMarkers = function (markerIds, preventCenter) {
        let self = this;
        if (!$.isArray(markerIds)) {
            markerIds = [markerIds];
        }

        for (let i= 0, markerId; markerId = markerIds[i]; i++) {
            let markerToRemove = self.marker.filter(function (o) {
                return o.dataId === markerId;
            })[0];

            self.map.__alp_lf__.removeLayer(markerToRemove);

            let index = self.marker.indexOf(markerToRemove);
            self.marker.splice(index, 1);
        }

        if (!preventCenter && self.marker.length > 0) {
            self.centerMap();
        }
    };

    Map.prototype.centerMap = function () {
        let self = this;
        let latlngs = [];

        if (self.geomLayer) {
            let geomLatlngbounds = self.geomLayer.getBounds();
            console.log(geomLatlngbounds);
            self.map.setCenter(geomLatlngbounds.getCenter());
            self.map.fitBounds(geomLatlngbounds);
        } else {
            for (let i= 0, marker; marker = this.marker[i]; i++) {
                latlngs.push(marker._latlng);
            }
            self.map.__alp_lf__.fitBounds(latlngs);
        }
    };

    Map.prototype.deactivateAllMarkers = function () {
        let self = this;

        for (let i = 0; i < self.marker.length; i++) {
            self.marker[i].isActive = false;
        }
    };

    Map.prototype.activeMarker = function (marker) {
        let self = this;
        marker.isActive = true;

        if(marker.dataInfoAjaxUrl || marker.infowindow || marker.infoBox) {
            self.openInfoBox(marker)
        }
    };

    Map.prototype.loadMarkerInfobox = function (url) {
        return fetch(url, {
            method: 'GET'
        }).then(respones => respones.text());
    };

    Map.prototype.openInfoBox = function (marker) {
        let self = this;
        if(marker.infoBox) {
            marker.bindPopup(marker.infoBox).openPopup();
        }
        if (!marker.infoBox && !marker.infowindow) {
            pendingDetailRequest = self.loadMarkerInfobox(marker.dataInfoAjaxUrl);

            pendingDetailRequest.then(function (result) {
                marker.infowindow = $(result);
                marker.bindPopup(result).openPopup();
                tripplanerInit($(marker.getPopup().getPane()));
                trustInit($(marker.getPopup().getPane()));
            });
        } else {
            marker.bindPopup(marker.infowindow).openPopup();
            tripplanerInit($(marker.getPopup().getPane()));
            trustInit($(marker.getPopup().getPane()));
        }
    };

    let mapElements = $('.js-map');
    onEnterViewPort(mapElements, () => {
        loadMapsAPI().then(() => {
                if (mapElements && mapElements.length) {
                    oax.api.maps(function (maps) {
                        alpsteinMap = maps;

                        mapElements.each(function (index) {
                            let $map = $(this);
                            if (!$map.find('.js-map__canvas').prop('id')) {
                                $map.find('.js-map__canvas').prop('id', 'map-' + index);
                            }

                            new Map($map);
                        });
                    });
                }
        });
    }, {offset: window.innerHeight * .5});


    $scope.find('.js-remove-map-xs-overlay').on('click', function (evt) {
        evt.preventDefault();
        $(this).closest('div').remove();
    });


}

// window.mapApiLoadedCallback = function () {
//     console.log('~~~~~~~~ mapApiLoadedCallback ~~~~~~~~');
// };