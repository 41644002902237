export function initInScope($scope) {
    let $dropdownToggle = $scope.find('.js-filter-dropdown__toggle');
    let $dropdownToggleScrollLock = $scope.find('.js-filter-dropdown__toggle:not(.js-filter-dropdown__toggle--price-sort)');
    let $dropdownToggleCloseXs = $scope.find('.js-filter-dropdown__toggle.dropdown-overlay__close');
    let $dropdownToggleClose = $scope.find('.js-filter-dropdown__close');


    $dropdownToggleScrollLock.click(function () {
        if (matchMedia('(max-width: 767px)').matches) {
            $('html').addClass('locked');
        }
    });


    $dropdownToggle.click(function () {
        let $overlay = $(this).closest('.js-filter-dropdown__container').find('.js-filter-dropdown--overlay');

        if($overlay.hasClass('show')) {
            $(this).removeClass('show');
            $overlay.removeClass('show');
            $('html').removeClass('locked');
            if ($('.js-filter-dropdown__container').hasClass('js-overlay--filters-changed')) {
                $('.js-room-form__submit').trigger('click');
            }
            $('.js-filter-dropdown__container').removeClass('js-overlay--filters-changed');
        } else {
            $('.js-filter-dropdown').removeClass('show');
            $('.js-filter-dropdown--overlay').removeClass('show');
            $(this).addClass('show');
            $overlay.addClass('show');
        }
    });

    $dropdownToggleClose.click(function () {
        $('.js-filter-dropdown').removeClass('show');
        $('.js-filter-dropdown--overlay').removeClass('show');
    });

    $dropdownToggleCloseXs.click(function () {
        if (matchMedia('(max-width: 767px)').matches) {
            $('html').removeClass('locked');
            $('.js-filter-dropdown').removeClass('show');
            $('.js-filter-dropdown--overlay').removeClass('show');
        }
    });

    $('.js-filter-dropdown__container input').on('change', function(){
        console.log('You changed a input inside the overlay');
        $('.js-filter-dropdown__container').addClass('js-overlay--filters-changed');
    });
}