import 'whatwg-fetch'; // IE10 Polyfill

export function initInScope ($scope) {
    let params = [];
    $scope.find('.js-trust-you').each(function () {
        params.push({
            name: 'id[]',
            value: $(this).data('trust-id')
        });
    });

    params.push({
        name: 'lang',
        value: _config.lang
    });

    fetch('/' + _config.lang +'/get-trust-score?' + $.param(params), {
            method: 'GET'
        })
        .then(response => response.json())
        .then(function (response) {
            $.each(response.items, function (key, value) {
                let trustYou = $('.js-trust-you[data-trust-id="' + key +'"]');
                trustYou.each(function () {
                    if (value.score === null) {
                        $(this).find('.js-trust-you__item').addClass('invisible');
                        $(this).find('.js-trust-you__item--no-score').removeClass('invisible');
                    } else {
                        $(this).removeClass('invisible');
                        $(this).find('.js-trust-you__item').removeClass('invisible');
                        $(this).find('.js-trust-you__score').text(value.score);
                    }
                });
            });
        })
        .catch(function (a,b,c) {
            console.error(a,b,c);
        });
}